import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { RxCross2 } from "react-icons/rx";
function Searchpage(props) {
    const navigate = useNavigate();
    const [Searchvalue, setSearchvalue] = useState("")
    const [SearchType, setSearchType] = useState("Interview")
    const showToastMessage = (data) => {
        toast.warning(data, {
            position: toast.POSITION.TOP_CENTER,
        });
    };
    function searchFunc() {
        if (Searchvalue.trim() === "" || Searchvalue.trim() === undefined || Searchvalue.trim() === null) {
            showToastMessage("Search Data Can not Empty")
        } else {
            navigate(`/Search-Interview-Recap/${SearchType}/${Searchvalue}`, { replace: true });
            props.close();
        }
    }
    
    return (
        <div>
            <Modal
                size="lg"
                show={props.show}
                onHide={props.close}
                aria-labelledby="example-modal-sizes-title-lg"
                style={{ opacity: "100%" }}
            >
                <div className="model-main-box">
                    <button onClick={() => { props.close() }} className="button-cros"><RxCross2 /></button>
                    <div className="serach-modal">
                        <div className="contents">
                            <h2>Search With Interview/Recap-TV Title</h2>
                        </div>
                        <div className="note search__guide search-radio-home">
                            <div className="search-radio-btn search-radio-one">
                                <input type="radio" name="filter" value="Interview" className="radio-checked" onChange={(e) => setSearchType(e.target.value)} checked={(SearchType === "Interview") ? true : false} />
                                <lable className="radio-title">Interview</lable>
                            </div>
                            <div className="search-radio-btn search-radio-tow ">
                                <input type="radio" name="filter" value="Recap-TV" className="radio-checked" onChange={(e) => setSearchType(e.target.value)} checked={(SearchType === "Recap-TV") ? true : false} />
                                <lable className="radio-title">Recap</lable>
                            </div>
                        </div>
                        <div className="search-container ">
                            <input type="text" id="search-input" placeholder="Search" tabindex="1" value={Searchvalue}
                                onChange={(e) => setSearchvalue(e.target.value)} />
                            <button onClick={() => { searchFunc() }} className="search-button">
                                Search
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
export default Searchpage;
