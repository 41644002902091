import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
// import moment from "moment-timezone";
// import { trackPromise } from "react-promise-tracker";
// import AudioPlayer from "react-h5-audio-player";
// import { Fade } from "react-slideshow-image";
import { Swiper as Swipe, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, EffectFade, Autoplay } from "swiper";
import { useLocation } from "react-router-dom";
import "swiper/css/bundle";
import "swiper/css";
import "swiper/css/autoplay";
SwiperCore.use([Pagination, EffectFade, Autoplay]);
export default function HeaderTopSectionForRoot(props) {
  const params = useLocation();

  const [GifimgeVideo, setGifimgeVideo] = useState([]);

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/homegif`)
      .then((response) => {
        // setGifimgeVideo(response.data.data[0].desktop_gif);
        setGifimgeVideo(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <div className={(params.pathname!=='/')? "top-header-top-other" :"top-header-top"}>
        <div className={(params.pathname!=='/')? "section-header-one-for-other" :"section-header-one"}>
          <Swipe
            slidesPerView={1}
            effect={"fade"}
            speed={1500}
            loop={false}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination, EffectFade]}
          >
            {GifimgeVideo.map((element, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className={(params.pathname!=='/')? "sliderdiv-for-other" :"sliderdiv"}>
                  <a href={element.external_link} target="_blank">
                    <img
                      src={`${process.env.REACT_APP_GIFTOPHEADER_IMGURL}${element.desktop_gif}`}
                    />
                    </a>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swipe>
        </div>
        
      </div>
    </>
  );
}
