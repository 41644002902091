import React, { useState, useEffect } from 'react'
import axios from "axios";
import { Swiper as Swipe, SwiperSlide } from "swiper/react";
import { Pagination, EffectFade, Autoplay } from "swiper";
import 'swiper/css/bundle'
import 'swiper/css';
import "swiper/css/autoplay";
export default function MobileAdsHeader() {
  const [GifimgeVideo, setGifimgeVideo] = useState([]);
  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/homegif`)
      .then((response) => {
        setGifimgeVideo(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
  }, []);
  return (
    <div className="header-top-mobile">
      <div className="header-top-gif">
        <Swipe
          slidesPerView={1}
          effect={"fade"}
          speed={1500}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Pagination, EffectFade]}
          className="mySWiper"
        >
          {GifimgeVideo.map((element, index) => {
            return (
              <SwiperSlide key={index}>
                <a href={element.external_link} target="_blank">
                <img src={`${process.env.REACT_APP_GIFTOPHEADER_IMGURL}${element.desktop_gif}`} />
                </a>
              </SwiperSlide>
            );
          })}
        </Swipe>
      </div>
    </div>
  )
}
